html, #app {
    margin: 0;
    width: 100%;
    height: 100%;
    z-index: -100;
}

nav {
    display: flex;
    gap: 30px;
    justify-content: center;
    text-decoration: underline;
}

#app {
    overflow: hidden;
    position: absolute;
    display: flex;
    justify-content: center;
    color: #ffffff;
    font-family: 'Montserrat', sans-serif;
    align-items: center;
    z-index: -100;
}

p {
    text-align: center;
    margin: 0;
}

#app h1 {
    --lineHeight: 20px;
    width: auto;
    text-transform: uppercase;
    margin: 0;
    font-size: 48px;
    text-align: center;
}

#app a {
    display: inline-block;
    text-decoration: none;
    color: #fff;
}

#app canvas {
    display: block;
    position: fixed;
    z-index: -1;
    top: 0;
}

.loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.loading-text {
    font-size: 24px;
    animation: fade-in-out 7s linear infinite;
}

@keyframes fade-in-out {
    0% {
        opacity: 0;
    }
    25% {
        opacity: 1;
    }
    75% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
.loading-bar-container {
    width: 300px;
    height: 30px;
    background-color: rgba(240, 240, 240, 0.11);
    border-radius: 15px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    border: 0.5px solid white;
    position: relative;
}
.loading-bar-container {
    width: 300px;
    height: 30px;
    background-color: rgba(240, 240, 240, 0.11);
    border-radius: 15px;
    overflow: hidden;
    display: flex;
    justify-content: flex-start;
    border: 0.5px solid white;
}

.loading-bar-contact {
    height: 100%;
    background-color: rgba(255, 255, 255, 0.23);
    animation: fillLoadingBarContact 60s linear;
}

@keyframes fillLoadingBarContact {
    from {
        width: 0.1%;
    }
    to {
        width: 100%;
    }
}

#hero {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    z-index: 100;
}

.loading-bar-progress {
    position: absolute;
    top: 50%; /* Move the top of the child element to the middle of the parent */
    left: 50%; /* Move the left side of the child element to the middle of the parent */
    transform: translate(-50%, -50%);
}

.loading-bar-progress::after {
    content: '...';
    position: relative;
    animation: dotAnimation 1.5s infinite;
}

@keyframes dotAnimation {
    33% {
        content: '.';
    }
    66% {
        content: '..';
    }
    100% {
        content: '...';
    }
}

.contact-form {
    max-width: 500px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.contact-form label {
    display: block;
    margin-bottom: 10px;
}
.contact-form input[type="text"],
.contact-form input[type="email"] {
    width: 80%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 3px;
}
.contact-form input[type="submit"] {
    background-color: rgba(0, 0, 0, 0.2);
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 3px;
    cursor: pointer;
    font-family: Montserrat;
}
.contact-form input[type="submit"]:hover {
    background-color: rgba(255, 255, 255, 0.26);
}

form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}